import React, { Component } from "react"
import { navigate } from "gatsby"
import Form from "react-bootstrap/Form"
//import ReactDOM from "react-dom"

export default class Home extends React.Component {
  componentDidMount() {
    window.location.replace("https://drive.google.com/file/d/1Mb8SFz62tURoriO3E0qJhCx3ydP35s1x/view?usp=sharing")
  }

  render() {
    return (
      <div />
    )
  }
}
